import { Routes, Route } from "react-router-dom";

import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import Home from "./pages/home";
import Service from "./pages/services";
import TermsPage from "./pages/termsOfUse/TermsPage";
import MusicPage from "./pages/musicPage/MusicPage";
import AstroloudMusic from "./pages/astroloudMusic/AstroloudMusic";
import { FooterProvider } from "./context/astroloudContext";
function App() {
  return (
    <FooterProvider>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/services" element={<Service />}></Route>
        <Route path="/policy" element={<PrivacyPolicy />}></Route>
        <Route path="/terms" element={<TermsPage />}></Route>
        <Route path="/videos" element={<MusicPage />}></Route>
        <Route path="/music" element={<AstroloudMusic />}></Route>
      </Routes>
    </FooterProvider>
  );
}

export default App;
