import React from "react";
import Navigation from "../../components/navigation";
import Footer from "../../components/Footer";
import styles from "../../styles/AstroloudMusic.module.css";
import styles2 from "../../styles/NewReleases.module.css";
import playlistOne from "../../assets/images/playlist-1.png";
import playlistTwo from "../../assets/images/playlist-2.png";
import playlistThree from "../../assets/images/playlist-3.png";
const AstroloudMusic = () => {
  return (
    <div className={styles.astroloudMusic}>
      <Navigation />
      <h2 className={styles.astroloudMusicHeader}> MUSIC</h2>
      <div className={styles.songsContainer}>
        <div className={styles.song}>
          <img src={playlistThree} alt="Astoloud song" />
          <div className={`${styles2.releaseDetails} ${styles.songDetails}`}>
            <h3>
              <span>EP</span> By MOORE
            </h3>
            <h2>MOORE OR LESS</h2>
            <a href="" className={styles2.playlistLink}>
              Stream
            </a>
          </div>
        </div>
        <div className={styles.song}>
          <img src={playlistOne} alt="Astoloud song" />
          <div className={`${styles2.releaseDetails} ${styles.songDetails}`}>
            <h3>
              <span>SINGLE</span> By MOORE
            </h3>
            <h2>WAP FT BLAQBONEZ</h2>
            <a href="" className={styles2.playlistLink}>
              Stream
            </a>
          </div>
        </div>
        <div className={styles.song}>
          <img src={playlistTwo} alt="Astoloud song" />
          <div className={`${styles2.releaseDetails} ${styles.songDetails}`}>
            <h3>
              <span>SINGLE</span> By MOORE
            </h3>
            <h2>WAP FT BLAQBONEZ</h2>
            <a href="" className={styles2.playlistLink}>
              Stream
            </a>
          </div>
        </div>
        <div className={`${styles.song} ${styles.fourthSong}`}>
          <img src={playlistTwo} alt="Astoloud song" />
          <div className={`${styles2.releaseDetails} ${styles.songDetails}`}>
            <h3>
              <span>SINGLE</span> By MOORE
            </h3>
            <h2>WAP FT BLAQBONEZ</h2>
            <a href="" className={styles2.playlistLink}>
              Stream
            </a>
          </div>
        </div>
        <div className={styles.song}>
          <img src={playlistThree} alt="Astoloud song" />
          <div className={`${styles2.releaseDetails} ${styles.songDetails}`}>
            <h3>
              <span>EP</span> By MOORE
            </h3>
            <h2>MOORE OR LESS</h2>
            <a href="" className={styles2.playlistLink}>
              Stream
            </a>
          </div>
        </div>
        <div className={styles.song}>
          <img src={playlistOne} alt="Astoloud song" />
          <div className={`${styles2.releaseDetails} ${styles.songDetails}`}>
            <h3>
              <span>SINGLE</span> By MOORE
            </h3>
            <h2>WAP FT BLAQBONEZ</h2>
            <a href="" className={styles2.playlistLink}>
              Stream
            </a>
          </div>
        </div>
      </div>
      <a href="" className={styles.loadLink}>
        Load More
      </a>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default AstroloudMusic;
