import React from "react";
import styles from "../../styles/MusicVideos.module.css";
import styles2 from "../../styles/MusicPage.module.css";
import tooSoftGif from "../../assets/images/too-soft.gif";
import wapGiff from "../../assets/images/wap.gif";
import furnitureGif from "../../assets/images/furniture.gif";
import Navigation from "../../components/navigation";
import Footer from "../../components/Footer";

const MusicPage = () => {
  return (
    <>
      <Navigation />
      <div className={styles2.musicPage}>
        <article className={styles.musicVideosContainer}>
          <div className={`${styles.singleBigImg} ${styles2.singleBigImg2} `}>
            <div className={styles.videoDetails}>
              <h2>
                MOORE DH FT BLAQBONEZ - <br />
                <span>WAP</span>
              </h2>
              <div className={styles.videoLink}>
                <a href="" className={styles.watchMoviesLink}>
                  WATCH VIDEO
                </a>
              </div>
            </div>
          </div>
          <div
            className={`${styles.smallImgContainer} ${styles2.smallImgContainer2}`}
          >
            <div
              className={`${styles.singleSmallPoster} ${styles2.singleSmallPoster2}`}
            >
              <div className={styles.videoDetails}>
                <h2>
                  MOORE DH FT- <br />
                  <span>FURNITURE (YOUTUBE SPECIAL)</span>
                </h2>
                <div className={styles.videoLink}>
                  <a href="" className={styles.watchMoviesLink}>
                    WATCH VIDEO
                  </a>
                </div>
              </div>
            </div>
            <div
              className={`${styles.singleSmallPoster} ${styles.lastImg} ${styles2.lastVideo}`}
            >
              <div className={styles.videoDetails}>
                <h2>
                  MOORE DH FT- <br />
                  <span>TOO SOFT</span>
                </h2>
                <div className={styles.videoLink}>
                  <a href="" className={styles.watchMoviesLink}>
                    WATCH VIDEO
                  </a>
                </div>
              </div>
            </div>
          </div>
        </article>
        <h2 className={styles2.pageHeader}>VIDEOS</h2>

        <div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default MusicPage;
