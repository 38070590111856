import { useState, useRef } from "react";
import styles from "../../src/styles/MusicVideos.module.css";
import tooSoftGif from "../../src/assets/images/tooSoft.png";
import wapGiff from "../assets/images/wap.gif";
import furnitureGif from "../assets/images/furniture.gif";

const MusicVideos = ({ isMarginTop }) => {
  const [isHovering, setIsHovering] = useState(false);
  const gifRef = useRef();

  const handleOnHover = () => {
    console.log("Hovered !!");
  };

  const handleOffHover = () => {
    console.log("Hover ended!");
  };

  return (
    <div className={`${isMarginTop ? styles.musicVideosSection : ""}`}>
      <h1 className={styles.musicVideosHeader}>Music Videos</h1>
      <article className={styles.musicVideosContainer}>
        <div className={styles.singleBigImg}>
          <div className={styles.videoDetails}>
            <h2>
              MOORE DH FT BLAQBONEZ - <br />
              <span>WAP</span>
            </h2>
            <div className={styles.videoLink}>
              <a href="" className={styles.watchMoviesLink}>
                WATCH VIDEO
              </a>
            </div>
          </div>
        </div>
        <div className={styles.smallImgContainer}>
          <div className={styles.singleSmallPoster}>
            <div className={styles.videoDetails}>
              <h2>
                MOORE DH FT- <br />
                <span>FURNITURE (YOUTUBE SPECIAL)</span>
              </h2>
              <div className={styles.videoLink}>
                <a href="" className={styles.watchMoviesLink}>
                  WATCH VIDEO
                </a>
              </div>
            </div>
          </div>
          <div className={`${styles.singleSmallPoster} ${styles.lastImg}`}>
            {/* <img

              src={tooSoftGif}
              alt="Furniture gif"
              onMouseEnter={handleOnHover}
              onMouseLeave={handleOffHover}
            /> */}

            <div className={styles.videoDetails}>
              <h2>
                MOORE DH FT- <br />
                <span>TOO SOFT</span>
              </h2>
              <div className={styles.videoLink}>
                <a href="" className={styles.watchMoviesLink}>
                  WATCH VIDEO
                </a>
              </div>
            </div>
          </div>
        </div>
      </article>
      <div className={styles.viewLinkContainer}>
        <a href="" className={styles.watchMoviesLink}>
          VIEW ALL VIDEOS
        </a>
      </div>
    </div>
  );
};

export default MusicVideos;
