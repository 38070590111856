import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    newReleases: [],
    loading: false
}

export const releaseSlice = createSlice({
    name: 'release',
    initialState,
    reducers: {
        ReleaseLoading: (state) => {
            state.loading = true
        },
        ReleaseLoaded: state => {
            state.loading = false
        },
        GetReleaseSuccess: (state, action) => {
            state.newReleases = action.payload?.releasesData
        },
        GetReleaseErr: (state) => {
            return state
        }
    },
})

// Action creators are generated for each case reducer function
export const { ReleaseLoaded, ReleaseLoading, GetReleaseErr, GetReleaseSuccess } = releaseSlice.actions

export default releaseSlice.reducer