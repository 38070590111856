import styles from "../../styles/NewReleases.module.css";
import ReleaseCard from "./ReleaseCard";
import playlistOne from "../../assets/images/playlist-1.png";
import playlistTwo from "../../assets/images/playlist-2.png";
import playlistThree from "../../assets/images/playlist-3.png";
import { useSelector } from "react-redux";

const NewReleases = () => {
  const { newReleases } = useSelector((state) => state.release);
  console.log(newReleases.releases);
  return (
    <div className={styles.newReleaseContainer}>
      <article className={styles.newReleaseHeader}>
        <h1 className={styles.headerText}> New Releases</h1>
        <div className={styles.directionIcons}>
          <div className={styles.directionIconContainer}>
            <i class="fa-solid fa-chevron-left"></i>
          </div>
          <div className={styles.directionIconContainer}>
            <i class="fa-solid fa-chevron-right"></i>
          </div>
        </div>
      </article>
      <div
        className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-4 lg:gap-x-8"
        style={{ placeItems: "center" }}
      >
        {newReleases?.releases
          ?.slice()
          .sort((a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt))
          .slice(0, 3)
          .map((item) => (
            <ReleaseCard
              key={item.id}
              songType={item?.releaseType}
              author={item?.artist}
              title={item?.name}
              url={item?.youtubeAudio}
              imageSrc={item?.albumArt}
            />
          ))}
        {/* <ReleaseCard
          songType="EP"
          author="MOORE"
          title="MORE OR LESS"
          url=""
          imageSrc={playlistThree}
        />
        <ReleaseCard
          songType="SINGLE"
          author="MOORE"
          title="WAP FT BLAQBONEZ"
          url=""
          imageSrc={playlistOne}
        />
        <ReleaseCard
          songType="SINGLE"
          author="MOORE"
          title="TOO SOFT"
          url=""
          imageSrc={playlistTwo}
        /> */}
      </div>
    </div>
  );
};

export default NewReleases;
