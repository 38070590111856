import styles from "../../styles/Service.module.css";

function ServiceCard({
  imgSrc,
  header,
  details,
  buttonText,
  isRight,
  isBlackBg,
  isChangeTextFlow,
}) {
  return (
    <div className={`${styles.serviceCard} ${isBlackBg ? styles.blackBg : ""}`}>
      <div
        className={`${styles.serviceCardImg} ${
          isRight ? styles.flexRight : ""
        }`}
      >
        <img src={imgSrc} alt="" />
      </div>
      <div
        className={`${styles.serviceDetails} ${
          isChangeTextFlow ? styles.textTop : ""
        } `}
      >
        <h2>{header}</h2>
        <p>{details}</p>
        <a href="#">{buttonText}</a>
      </div>
    </div>
  );
}

export default ServiceCard;
