import styles from "../../styles/Service.module.css";
import logo from "../../assets/images/logomark-chrome 1.png";
import serviceImgOne from "../../assets/images/service-1.png";
import serviceImgTwo from "../../assets/images/service-2.png";
import serviceImgThree from "../../assets/images/service-3.png";
import serviceImgFour from "../../assets/images/service-4.png";
import teamMemberOne from "../../assets/images/team-member-1.png";
import teamMemberTwo from "../../assets/images/team-member-2.png";

import Navigation from "../../components/navigation";
import Footer from "../../components/Footer";
import ServiceCard from "./ServiceCard";
const Service = () => {
  return (
    <div className={styles.servicePage}>
      <Navigation />
      <header className={styles.serviceHeader}>
        <div className={styles.headerImg}>
   
            {" "}
            <img src={logo} alt="" />
    
        </div>
        <h1>
          pushing beyond the <br /> boundaries of afrobeat
        </h1>
        <p>
          Astroloud provides comprehensive services to support the success of
          our <br />
          artists. We are dedicated to providing our artists with all the
          resources they <br />
          need to succeed in the music industry.
        </p>
      </header>

      <section classname={styles.serviceSections}>
        <ServiceCard
          imgSrc={serviceImgFour}
          header="MUSIC PRODUCTION"
          details="Our music production team works closely with our artists to create high-quality, original recordings."
          buttonText="BOOK A SESSION"
          isRight={false}
          isBlackBg={true}
          isChangeTextFlow={false}
        />
        <ServiceCard
          imgSrc={serviceImgOne}
          header="MARKETING AND PR"
          details="Our marketing and PR department promotes the music and helps to increase visibility and fan engagement."
          buttonText="Contact Us"
          isRight={true}
          isBlackBg={false}
          isChangeTextFlow={true}
        />
        <ServiceCard
          imgSrc={serviceImgTwo}
          header="TALENT MANAGEMENT"
          details="Our talent management team provides guidance and support to help our artists develop their careers and reach their full potential."
          buttonText="Contact Us"
          isRight={false}
          isBlackBg={true}
          isChangeTextFlow={false}
        />
        <ServiceCard
          imgSrc={serviceImgThree}
          header="DISTRIBUTION"
          details="And our distribution team ensures that our music is widely available across various platforms and outlets."
          buttonText="Contact Us"
          isRight={true}
          isBlackBg={false}
          isChangeTextFlow={true}
        />
      </section>
      <section className={styles.astroloudDetails}>
        <div>
          <h2>
            100% UP TO <br /> <span>SOMETHING ASTRO</span>
          </h2>
          <p className={styles.detailsText}>
            Astroloud is a record label that promotes new and talented
            alternative afrobeats artists and aims to create a unique experience
            for them and their fans.
          </p>
          <p className={styles.detailsMiddleText}>
            {" "}
            Showcasing the afrobeats culture
          </p>
          <p className={styles.detailsText}>
            We are based in London, Dublin, Lagos, and Accra, Astroloud Music is
            more than just a record label – we are a full-service artist
            development, music production, marketing, and distribution
            powerhouse, committed to helping our artists find their place in the
            market and reach their full potential.
          </p>
        </div>
        <article className={styles.astroTeam}>
          <div className={styles.singleMember}>
            <div className={styles.memberInfo}>
              <p>ARTISTE / EXEC</p>
              <h4>MOORE DH</h4>
            </div>
            <div className={styles.astroloudTeamMember}>
              <img src={teamMemberTwo} alt="Team Member" />
            </div>
          </div>
          <div className={styles.singleMember}>
            <div className={styles.memberInfo}>
              <p>HEAD OF MUSIC</p>
              <h4>JOHNSON IP</h4>
            </div>
            <div className={styles.astroloudTeamMember}>
              <img src={teamMemberOne} alt="Team Member" />
            </div>
          </div>
        </article>
      </section>
      <div className={styles.serviceFooter}>
        <Footer />
      </div>
    </div>
  );
};

export default Service;
