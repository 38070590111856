import React from "react";
import styles from "../../src/styles/NewReleases.module.css";
import stylesTwo from "../../src/styles/CuratedPlaylists.module.css";

import curatedImgOne from "../assets/images/curated-1.png";
import curatedImgTwo from "../assets/images/curated-2.png";
import curatedImgThree from "../assets/images/curated-3.png";
// import curatedImgFour from "../assets/images/curated-4.png";

const CuratedPlaylist = () => {
  return (
    <div>
      <article className={styles.newReleaseHeader}>
        <h1 className={styles.headerText}>Curated Playlists</h1>
        <div className={styles.directionIcons}>
          <div className={styles.directionIconContainer}>
            <i class="fa-solid fa-chevron-left"></i>
          </div>
          <div className={styles.directionIconContainer}>
            <i class="fa-solid fa-chevron-right"></i>
          </div>
        </div>
      </article>
      <article className={stylesTwo.curatedList}>
        <div className={stylesTwo.playlistItem}>
          <img src={curatedImgOne} alt="" />
          <div className={stylesTwo.itemDetails}>
            <h2>
              TOP BOBS
              <br />
              <span>ASTROLOUD UK</span>
            </h2>
            <div className={stylesTwo.itemLink}>
              <a href="" className={stylesTwo.streamLink}>
                STREAM
              </a>
            </div>
          </div>
        </div>
        <div className={stylesTwo.playlistItem}>
          <img src={curatedImgTwo} alt="" />
          <div className={stylesTwo.itemDetails}>
            <h2>
              TOP BOBS
              <br />
              <span>ASTROLOUD UK</span>
            </h2>
            <div className={stylesTwo.itemLink}>
              <a href="" className={stylesTwo.streamLink}>
                STREAM
              </a>
            </div>
          </div>
        </div>
        <div className={stylesTwo.playlistItem}>
          <img src={curatedImgThree} alt="" />
          <div className={stylesTwo.itemDetails}>
            <h2>
              TOP BOBS
              <br />
              <span>ASTROLOUD UK</span>
            </h2>
            <div className={stylesTwo.itemLink}>
              <a href="" className={stylesTwo.streamLink}>
                STREAM
              </a>
            </div>
          </div>
        </div>
        <div className={stylesTwo.playlistItem}>
          <img src={curatedImgOne} alt="" />
          <div className={stylesTwo.itemDetails}>
            <h2>
              TOP BOBS
              <br />
              <span>ASTROLOUD UK</span>
            </h2>
            <div className={stylesTwo.itemLink}>
              <a href="" className={stylesTwo.streamLink}>
                STREAM
              </a>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

export default CuratedPlaylist;
