import { GetReleaseErr, GetReleaseSuccess, ReleaseLoaded, ReleaseLoading } from "../reducers"
import axios from 'axios';

axios.defaults.baseURL = `https://astroloud-server.herokuapp.com`;
axios.defaults.headers.post['Content-Type'] = 'application/json';

export const getNewReleases = () => async (dispatch) => {
    dispatch(ReleaseLoading())
    try {
        const res = await (await axios.get('/v1/releases')).data
        dispatch(ReleaseLoaded())
        console.log(res)
        dispatch(GetReleaseSuccess(res?.data))
    } catch (error) {
        dispatch(ReleaseLoaded())
        dispatch(GetReleaseErr())
        console.log('Error getting new releases')
    }
}