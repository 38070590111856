import React from "react";
import { createContext } from "react";
export const FooterContext = React.createContext();

export function FooterProvider(props) {
  const { children, footerRef } = props;

  return (
    <FooterContext.Provider value={footerRef}>
      {children}
    </FooterContext.Provider>
  );
}
