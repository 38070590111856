import Navigation from "../../components/navigation";
import Footer from "../../components/Footer";
import styles from "../../styles/Privacy.module.css";

const PrivacyPolicy = () => {
  return (
    <div className={styles.privacyPolicyPage}>
      <Navigation />
      <div className={styles.privacyDetailsSection}>
        <h2 className={styles.privacyHeader}>Privacy Policy</h2>
        <article className={styles.privacyText}>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
            Repellendus, dolores consequuntur assumenda velit laboriosam et
            excepturi quas sequi saepe sapiente? Recusandae at aspernatur
            pariatur delectus! Ipsum sunt modi natus vero repellat culpa
            explicabo porro aliquid nostrum! Voluptatem dolorem quis
            reprehenderit eius voluptatum possimus, modi delectus perspiciatis
            asperiores mollitia error labore earum fugit qui rem deserunt
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
            Repellendus, dolores consequuntur assumenda velit laboriosam et
            excepturi quas sequi saepe sapiente? Recusandae at aspernatur
            pariatur delectus! Ipsum sunt modi natus vero repellat culpa
            explicabo porro aliquid nostrum! Voluptatem dolorem quis
            reprehenderit eius voluptatum possimus, modi delectus perspiciatis
            asperiores mollitia error labore earum fugit qui rem deserunt
            inventore accusantium necessitatibus nemo deleniti optio illo.
            Commodi pariatur similique distinctio, nisi aspernatur iure impedit
            quaerat illum minima exercitationem doloribus? Vitae dolore dolores
            numquam sit qui dolorem alias expedita provident recusandae quaerat
            eligendi possimus consectetur, commodi inventore ratione explicabo
            molestias libero magnam, dicta pariatur omnis.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
            Repellendus, dolores consequuntur assumenda velit laboriosam et
            excepturi quas sequi saepe sapiente? Recusandae at aspernatur
            pariatur delectus! Ipsum sunt modi natus vero repellat culpa
            explicabo porro aliquid nostrum! Voluptatem dolorem quis
            reprehenderit eius voluptatum possimus, modi delectus perspiciatis
            asperiores mollitia error labore earum fugit qui rem deserunt
            inventore accusantium necessitatibus nemo deleniti optio illo.
            Commodi pariatur similique distinctio, nisi aspernatur iure impedit
            quaerat illum minima exercitationem doloribus? Vitae dolore dolores
            numquam sit qui dolorem alias expedita provident recusandae quaerat
            eligendi possimus consectetur, commodi inventore ratione explicabo
            molestias libero magnam, dicta pariatur omnis.
          </p>
        </article>
      </div>
      <div className={styles.privacyFooter}>
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
