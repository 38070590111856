import styles from "../styles/SubscribeForm.module.css";

const SubscribeForm = ({ handleSubscribe, email, setEmail, error }) => {
  return (
    <footer className={styles.subscribeContainer}>
      <h3>Stay updated with Astroloud</h3>
      <form className={styles.footerForm} onSubmit={handleSubscribe}>
        <div className={styles.subscribeFormContainer}>
          <div className={styles.subscribeInputContainer}>
            {" "}
            <input
              type="text"
              placeholder="ENTER YOUR EMAIL"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={styles.subscribeInputButton}>
            <button type="submit">SUBSCRIBE</button>
          </div>
        </div>
        <p className={styles.errorText}>{error}</p>
      </form>
    </footer>
  );
};

export default SubscribeForm;
