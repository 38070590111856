import styles from "../../styles/NewReleases.module.css";

const ReleaseCard = ({ title, author, songType, url, imageSrc }) => {
  return (
    <div className={styles.releaseCard}>
      <div className={styles.releaseCardImg}>
        <img src={imageSrc} alt="" />
      </div>
      <div className={styles.releaseDetails}>
        <h3>
          <span>{songType}</span> By {author}
        </h3>
        <h2>{title}</h2>
        <a
          target={"_blank"}
          rel="noreferrer"
          href={url}
          className={styles.playlistLink}
        >
          Stream
        </a>
      </div>
    </div>
  );
};

export default ReleaseCard;
