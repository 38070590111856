import styles from "../styles/HeroLinks.module.css";
const HeroLinks = () => {
  return (
    <ul className={styles.heroLinks}>
      <li>
        <a
          href="https://www.youtube.com/watch?v=-vr4pBcT94k"
          target="_blank"
          rel="noreferrer"
        >
          LISTEN NOW
        </a>
      </li>
      <li>
        <a
          href="https://www.youtube.com/watch?v=-vr4pBcT94k"
          target="_blank"
          rel="noreferrer"
        >
          WATCH VIDEO
        </a>
      </li>
    </ul>
  );
};

export default HeroLinks;
