import { useState, useEffect, useRef } from "react";

import styles from "../../styles/Home.module.css";
import Navigation from "../../components/navigation";
import HeroLinks from "../../components/HeroLinks";
import NewReleases from "../../components/releases/NewReleases";
import CuratedPlaylist from "../../components/CuratedPlaylist";
import MusicVideos from "../../components/MusicVideos";
import Footer from "../../components/Footer";
import SubscribeForm from "../../components/SubscribeForm";
import { isValidEmail } from "../../utils/validateEmail";
import LoadingPage from "../../components/loadingScreen";
import { useDispatch } from "react-redux";
import { getNewReleases } from "../../features/actions";

const Home = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [hasScrolled, setHasScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0 && !hasScrolled) {
      setHasScrolled(true);
    }
  };

  useEffect(() => {
    // Set hasScrolled to false on component mount
    setHasScrolled(false);

    window.addEventListener("scroll", handleScroll, true);
    return () => window.removeEventListener("scroll", handleScroll, true);
  }, []);

  useEffect(() => {
    dispatch(getNewReleases());
  }, [dispatch]);

  const handleSubscribe = (e) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      setError("*PLEASE ENTER VALID EMAIL");
    } else {
      console.log("Successful");
      setError("");
    }
  };

  useEffect(() => {
    const img = new Image();
    img.src = "../../assets/images/furniture-home";
    img.onload = () => {
      console.log("loaded")
      setHasScrolled(true);
    };

    img.onerror = (e) => {
      console.log(e)
      setHasScrolled(true);
    };
  }, []);
  return (
    <div ref={ref} onScroll={handleScroll}>
      {hasScrolled ? (
        <div className={`${styles.home}`}>
          <section className={styles.heroSection}>
            <Navigation />
            <HeroLinks />
          </section>
          <section className={styles.homePageSections}>
            <NewReleases />
            <MusicVideos isMarginTop={true} />
            <CuratedPlaylist />
            <SubscribeForm
              email={email}
              handleSubscribe={handleSubscribe}
              error={error}
              setEmail={setEmail}
            />
            <Footer />
          </section>
        </div>
      ) : (
        <LoadingPage />
      )}
    </div>
  );
};

export default Home;
