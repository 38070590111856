import React from "react";
import styles from "../../styles/LoadingPage.module.css";

import loadingLogo from "../../assets/images/loading-logo.png";
import loadingLogoText from "../../assets/images/loading-logo-text.png";
const LoadingPage = () => {
  return (
    <div className={styles.loadingPageContainer}>
      <div className={styles.loadingPage}>
        <article className={styles.landingPageHeader}>
          <div className={styles.landingItem}>
            <p>Distribution</p>
          </div>
          <div className={styles.landingItem}>
            <p>Music Production</p>
          </div>
          <div className={styles.landingItem}>
            <p>Marketing & pr</p>
          </div>
          <div className={styles.landingItem}>
            <p>Management</p>
          </div>
        </article>
        <article className={styles.loadingBody}>
          <div className={styles.logoContainer}>
            <img src={loadingLogo} alt="Loading Logo" />
          </div>
          <div>
            <img src={loadingLogoText} alt="Logo text" />
          </div>
        </article>
        <article className={styles.landingPageFooter}>
          <div className={styles.landingItem}>
            <p>Distribution</p>
          </div>
          <div className={styles.landingItem}>
            <p>Music Production</p>
          </div>
          <div className={styles.landingItem}>
            <p>Marketing & pr</p>
          </div>
          <div className={styles.landingItem}>
            <p>Management</p>
          </div>
        </article>
      </div>
      <div className={styles.scrollSpace}></div>
    </div>
  );
};

export default LoadingPage;
