import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import menuBar from "../../assets/images/menu-bar.png";
import logo from "../../assets/images/logo.png";
import closeBtn from "../../assets/images/close-btn.png";
import styles from "../../styles/Navigation.module.css";

const SideMenu = () => {
  const [showMenu, setShowMenu] = useState(false);
  const handleSideMenu = () => {
    setShowMenu(!showMenu);
    console.log("Hey");
  };
  const handleCloseSideMenu = () => {
    setShowMenu(false);
  };
  useEffect(() => {
    console.log(showMenu);
  }, [showMenu]);
  return (
    <div className={styles.sideMenuContainer}>
      <article className={styles.sideMenuHeader}>
        <div className={styles.sideMenuLogo}>
          <img src={logo} alt="Logo" />
        </div>
        <div
          className={`${styles.sideMenuBar} ${showMenu ? "hideMenuBar" : ""}`}
          onClick={handleSideMenu}
        >
          <img src={menuBar} alt="Menu Bar" />
        </div>
      </article>

      <div className={`${styles.sideMenu} ${showMenu ? styles.open : ""}`}>
        <article className={styles.sideMenuNav}>
          <div className={styles.closeBtn} onClick={handleCloseSideMenu}>
            <img src={closeBtn} alt="Menu Bar" />
          </div>
        </article>
        <div className={styles.mobileNavLinkSection}>
          <ul className={styles.mobileNavLinks}>
            <li>
              <Link to="/music">MUSIC</Link>
            </li>
            <li>
              <Link to="/videos">VIDEOS</Link>
            </li>

            <li>
              <Link to="/services">SERVICES</Link>
            </li>
            <li>
              <a href="#">BLOG</a>
            </li>
          </ul>
          <ul className={styles.mobileNavIcons}>
            <li>
              <a href="#">
                <i className="fa-brands fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href="#" className={styles.twitterIcon}>
                <i className="fa-brands fa-twitter"></i>
              </a>
            </li>
            <li>
              <a href="#" className={styles.youtubeIcon}>
                <i className="fa-brands fa-youtube"></i>
              </a>
            </li>
          </ul>
          <form className={styles.sideBarForm}>
            <div>
              <input
                type="text"
                placeholder="ENTER YOUR EMAIL"
                className={styles.sideMenuInput}
              />
            </div>
            <button>JOIN NEWSLETTER</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
