import { Link } from "react-router-dom";
import styles from "../styles/Footer.module.css";
import logo from "../../src/assets/images/badge.png";

const Footer = () => {
  return (
    <div className={styles.footerSection}>
      <article className={styles.footerDetails}>
        <div className={styles.footerLogo}>
          <img src={logo} alt="" />
        </div>
        <div className={styles.footerLinksContainer}>
          <ul className={styles.footerItems}>
            <li>
              <Link to="/music">MUSIC</Link>
            </li>
            <li>
              <Link to="/videos">VIDEOS</Link>
            </li>
            <li>
              <Link to="/services">SERVICES</Link>
            </li>
          </ul>
          <ul className={styles.footerItems}>
            <li>
              <a href="#">BLOG</a>
            </li>
            <li>
              <Link to="/policy" className={styles.footerLink}>
                PRIVACY
              </Link>
            </li>
            <li>
              <Link to="/terms">TERMS OF USE</Link>
            </li>
            <li></li>
          </ul>
        </div>
      </article>
      <article className={styles.copywrightSection}>
        <p>© 2023 ASTROLOUD</p>
        <ul className={styles.footerIcons}>
          <li>
            <a href="#">
              <i class="fa-brands fa-instagram"></i>
            </a>
          </li>
          <li>
            <a href="#" className={styles.twitterIcon}>
              <i class="fa-brands fa-twitter"></i>
            </a>
          </li>
          <li>
            <a href="#" className={styles.youtubeIcon}>
              <i class="fa-brands fa-youtube"></i>
            </a>
          </li>
        </ul>
      </article>
    </div>
  );
};

export default Footer;
