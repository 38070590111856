import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import styles from "../../styles/Navigation.module.css";
import logo from "../../assets/images/logo.png";
import SideMenu from "../sideMenu";

const Navigation = () => {
  const [hideMenu, setHideMenu] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 2800) {
        setHideMenu(true);
      } else {
        setHideMenu(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <article className={styles.navigation}>
        <div className={`${styles.desktopNav} ${hideMenu ? styles.hide : ""}`}>
          <Link to="/" className={styles.navLogo}>
            <img src={logo} alt="Astroloud logo" />
          </Link>
          <div className={styles.navLinkSection}>
            <ul className={styles.navLinks}>
              <li>
                <Link to="/music" className={styles.navItemLink}>
                  MUSIC
                </Link>
              </li>
              <li>
                <Link to="/videos">VIDEOS</Link>
              </li>

              <li>
                <Link to="/services">SERVICES</Link>
              </li>
              <li>
                <a href="#">BLOG</a>
              </li>
            </ul>
            <ul className={styles.navIcons}>
              <li>
                <a href="https://instagram.com/astroloud__?igshid=YmMyMTA2M2Y=">
                  <i class="fa-brands fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/astroloud__?s=21&t=DX9XOT2yzN5cUyEPv_tCEA"
                  className={styles.twitterIcon}
                >
                  <i class="fa-brands fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#" className={styles.youtubeIcon}>
                  <i class="fa-brands fa-youtube"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </article>
      <SideMenu />
    </div>
  );
};

export default Navigation;
